import React from "react";
import cogoToast from "cogo-toast";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Home from "./Home";
import { Outlet } from "react-router-dom";
export const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append(
      "f4cb950d-6c6f-4d69-9981-79a7fdae1af7",
      "YOUR_ACCESS_KEY_HERE"
    );

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      cogoToast.success("This message has been sent..!!");
    }
  };
  return (
    <>
      <div id="contact">
        <div className="contact-container">
          <form className="conatct-left" onSubmit={onSubmit}>
            <div className="contact-left-title">
              <h2>Get in touch</h2>
              <hr />
            </div>

            <input
              type="hidden"
              name="access_key"
              value="f4cb950d-6c6f-4d69-9981-79a7fdae1af7"
            />
            <input
              type="text"
              name="name"
              id=""
              placeholder="  Name"
              className="contact-inputs"
            />

            <input
              type="text"
              name="phone no"
              id=""
              placeholder=" Phone no"
              className="contact-inputs"
            />
            <input
              type="email"
              name="email"
              id=""
              placeholder=" Email"
              className="contact-inputs"
            />
            <textarea
            
              name="message"
              id=""
              placeholder="your message"
              className="contact-input"
              rows="10"
            ></textarea>
            <button>
              Submit <FaArrowAltCircleRight />{" "}
            </button>
          </form>
          <div className="contact-right">
            <div className="main-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.5057757752093!2d77.33769621214078!3d28.70442717552402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfbcc3843d4e1%3A0x1d5240f0428f5238!2sTech%20Safety!5e0!3m2!1sen!2sin!4v1713374608674!5m2!1sen!2sin"
                width="700"
                height="450"
                allowfullscreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <Outlet/>
      </div>
    </>
  );
};
