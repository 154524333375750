import React from "react";

import Img3 from "../assets/Icos/Team.png";
import Img4 from "../assets/Icos/manufacturing.png";
import Img5 from "../assets/Icos/technical-support.png";
import Img6 from "../assets/Icos/navigation.png";
const Team = () => {
  return (
    <>
<div data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">

      <div className="Main-columns">
        <div className="colmuns-1">
          <img src={Img3} alt="" />
          <h1>R&D Team</h1>
          <p>
            With over 200 employees working in the UK marketing center,
            China-R&D and supply chain center.
          </p>
        </div>
        <div className="colmuns-2">
          <img src={Img4} alt="" />
          <h1>99.9% Customer Praises</h1>
          <p>
            We have a professional NFPA standard engineer team to support
            project designs.
          </p>
        </div>
        <div className="colmuns-3">
          <img src={Img5} alt="" />
          <h1>Top-ranked Manufacturer</h1>
          <p>
            ASENWARE is a well-known first-class manufacturer with high-quality
            products.
          </p>
        </div>
        <div className="colmuns-4">
          <img src={Img6} alt="" />
          <h1>15+ Projects</h1>
          <p>
            ASENWARE fire safety products, solutions and services are used in
            over 3500 projects all over the world.
          </p>
        </div>
      </div>
      </div>
    </>
  );
};

export default Team;
