import React from "react";
import Img1 from "../assets/H-1.jpg";
import Img2 from "../assets/H-2.jpg";
import Img3 from "../assets/H-3.jpg";
import Client from "./Clint";

const Service = () => {
  return (
    <>
      <div className="High-container" id="service">
        <div className="box-1">
        <div data-aos="zoom-in-down">
          <img src={Img1} alt="" /></div>
          <h1>Autocall Fire Alarm Panels</h1>
          <p>Heavy Fire Protection for Light Commercial Building</p>
        </div>
        <div className="box-2">
        <div data-aos="zoom-in-left">
          <img src={Img2} alt="" />
          </div>
          <h1>NFS2-640 Honeywell Notifier Fire Control Panel</h1>
          <p>
            The NFS2-640 intelligent fire alarm control panel is p art of
            NOTIFIER's UL listed ONYX Series. It's designed specif ically for
            mid-size applications, and easily expanded vi a NOTIFIER's
            intelligent fire alarm network.
          </p>
        </div>
        <div className="box-3">
        <div data-aos="zoom-in-right">
          <img src={Img3} alt="" />
          </div>
          <h1>Detect Fire ABC Fire Extinguisher</h1>
          <p>
            6kg powder portable fire extinguisher (stored pressure/gas
            cartridge type)
          </p>
        </div>
      </div>
      <Client />
    </>
  );
};

export default Service;
