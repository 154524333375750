import "./App.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./responsive.css";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import Footer from "./components/Pages/Footer";
import Routting from "./Router/Routting";
import { Contact } from "./components/Pages/Contact";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <Routting />
    
       <Footer />
       
      
      <div className="whatapps">
        <a
          href="https://wa.me/+919315463262"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>
      </div>
    </>
  );
}

export default App;
