import React from "react";


import "./Dproduct.css";
const Dproduct = (props) => {
  console.log(props);
  return (
    <>
      <div className="product-card" id="top-product">
        <div className="item1">
          <img src={props.imgsrc} alt="" />
          <h4>{props.heading}</h4>
        </div>
      </div>
    </>
  );
};

export default Dproduct;
