import Brand1 from "../assets/Clint-logo/Barand-1.jpg";
import Brand2 from "../assets/Clint-logo/Barand-1.jpg";
import Brand3 from "../assets/Clint-logo/Barand-3.jpg";
import Brand4 from "../assets/Clint-logo/Barand-4.jpg";
import Brand5 from "../assets/Clint-logo/Barand-5.jpg";
import Brand6 from "../assets/Clint-logo/Barand-6.jpg";
import Brand7 from "../assets/Clint-logo/Barand-7.jpg";
import Brand8 from "../assets/Clint-logo/Barand-8.jpg";
import Brand9 from "../assets/Clint-logo/Barand-9.jpg";
import Brand10 from "../assets/Clint-logo/Barand-10.jpg";
import Brand11 from "../assets/Clint-logo/Barand-11.jpg";
import Brand12 from "../assets/Clint-logo/Barand-12.jpg";
import Brand13 from "../assets/Clint-logo/Barand-13.jpg";
import Brand14 from "../assets/Clint-logo/Barand-14.jpg";
import Brand15 from "../assets/Clint-logo/Barand-15.jpg";
import Brand16 from "../assets/Clint-logo/Barand-16.jpg";
import Brand17 from "../assets/Clint-logo/Barand-17.jpg";
import Brand18 from "../assets/Clint-logo/Barand-18.jpg";
import Brand19 from "../assets/Clint-logo/Barand-19.jpg";
import Brand20 from "../assets/Clint-logo/Barand-20.jpg";
import Brand21 from "../assets/Clint-logo/Barand-21.jpg";
import Brand22 from "../assets/Clint-logo/Barand-22.jpg";
import Brand23 from "../assets/Clint-logo/Barand-23.jpg";




import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-fast-marquee";
import React from "react";


const Client = () => {
  return (
    <>

      <div className="clint-area">
        <div className="clint-container">
        <div data-aos="fade-left"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="500">

          <h2>
            Our <span>Brands</span>
            <div className="line-1"></div>
          </h2>
          </div>

          <Marquee autoFill pauseOnClick direction="left">
            <section className=" logo-area">
              <div className="side">
                <img src={Brand1} alt="" />
              </div>
              <div className="side">
                <img src={Brand2} alt="" />
              </div>
              <div className="side">
                <img src={Brand3} alt="" />
              </div>
              <div className="side">
                <img src={Brand4} alt="" />
              </div>
              <div className="side">
                <img src={Brand5} alt="" />
              </div>
              <div className="side">
                <img src={Brand6} alt="" />
              </div>
              <div className="side">
                <img src={Brand7} alt="" />
              </div>
              <div className="side">
                <img src={Brand8} alt="" />
              </div>
              <div className="side">
                <img src={Brand9} alt="" />
              </div>
              <div className="side">
                <img src={Brand10} alt="" />
              </div>

              <div className="side">
                <img src={Brand11} alt="" />
              </div>
              
              
              <div className="side">
                <img src={Brand12} alt="" />
              </div>
              
            </section>
          </Marquee>

          <Marquee direction="right" autoFill pauseOnClick>
            <section className=" logo-area">
              <div className="side">
                <img src={Brand13} alt="" />
              </div>
              <div className="side">
                <img src={Brand14} alt="" />
              </div>
              <div className="side">
                <img src={Brand15} alt="" />
              </div>
              <div className="side">
                <img src={Brand16} alt="" />
              </div>
              <div className="side">
                <img src={Brand17} alt="" />
              </div>
              <div className="side">
                <img src={Brand12} alt="" />
              </div>
              <div className="side">
                <img src={Brand20} alt="" />
              </div>
              <div className="side">
                <img src={Brand18} alt="" />
              </div>
              <div className="side">
                <img src={Brand19} alt="" />
              </div>
              <div className="side">
                <img src={Brand21} alt="" />
              </div>
              
              <div className="side">
                <img src={Brand22} alt="" />
              </div>
              <div className="side">
                <img src={Brand23} alt="" />
              </div>
              
              
              
              
            </section>
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default Client;
