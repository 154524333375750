import About from "./About";
import License from "./License";
import Product from "./Product";
import Service from "./Service";
import { useNavigate } from "react-router-dom";

import { Contact } from "./Contact";

import AOS from 'aos';
import { useEffect } from "react";
const Home = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  const navigate = useNavigate();
  return (
    <>
      <div className="container" id="home">
        <div className="contact">
     
        <div
      data-aos="fade-up"
      
     
    >
    
   

          <h1>
            Powerful
            <br /> Security Protection
          </h1>
          </div>
          <p> We focus on long-term relationships with customers.</p>
          
          <div className="btn-group">
            <button className="btn-1" onClick={() => navigate("/our-product")}>
              View Products
            </button>
            <button onClick={() => navigate("/contact")} className="btn-2">
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <About />
      <Service />
      <Product />
      <License />
      <Contact/>
    </>
  );
};

export default Home;
