import React from "react";
import ParentProuct from "../ProductMap/ParentProuct";

const Product = () => {
  return (
    <div className="mob-res">
      <ParentProuct />
    </div>
    
  );
};

export default Product;
