import React from 'react'
import working from "../../assets/working-svg.svg";
const AllProduct = () => {
  return (
  <>
<div className='tem-working'>
    
  <h1>Comeing Soon</h1>
  <img src={working} alt=''/>
</div>
  </>
  )
}

export default AllProduct