import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-col">
          <h4>Home</h4>
          <ul>
            <li>
              <Link to="home"> Home</Link>
            </li>

            <li>
              <Link to="about"> About Us</Link>
            </li>
            <li>
              <Link to="product"> Products</Link>
            </li>

            <li>
              <Link to="service"> Service</Link>
            </li>
            <li>
              <Link to="com-licence"> License</Link>
            </li>
            <li>
              <Link to="contact"> Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>
            Head Office <IoLocationOutline />
          </h4>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.google.com/maps?ll=28.704427,77.340277&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=2112822575843201592"
              >
                <p>
                  Krishna Vihar, M-20, Kuti Rd, Bhopura, Ghaziabad, Uttar
                  Pradesh 201005,
                  <br/>
                  INDIA
                </p>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>Products</h4>
          <ul>
            <li>
              <Link to=""> Fire alarm system</Link>
            </li>
            <li>
              <Link to="top-product"> Emergency system</Link>
            </li>
            <li>
              <Link to="top-product">Fire fighting system</Link>
            </li>
            <li>
              <Link to="top-product"> Fire suppression system</Link>
            </li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>
            Inquiries <MdOutlinePhoneIphone />
          </h4>
          <ul>
            <li>
              <a href="tel:+91 8826972993">
                <p>
                  For any inquiries, questions or recommendations,<br/> please call:
                  +91 8826972993
                </p>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>Follow us</h4>
          <div className="links">
            <a
              target="_blank"
              href="https://www.facebook.com/people/SafetyTech-H/61552983945991/"
            >
              <FaFacebookF color="#fff" size={20} />
            </a>
            <a target="_blank" href="https://www.instagram.com/safetytechhh/">
              <FaInstagram color="#fff" size={20} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/tech-safety-4734162a8/  "
            >
              <FaLinkedin color="#fff" size={20} />
            </a>
          </div>
        </div>
        <div className="copyright">
          <h3>Copyright © 2024 Tech Safety Ltd. All Rights Reserved.</h3>
        </div>
      </footer>
    </>
  );
};

export default Footer;
