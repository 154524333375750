import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../components/Pages/Home";
import About from "../components/Pages/About";
import Service from "../components/Pages/Service";
import License from "../components/Pages/License";
import { Contact } from "../components/Pages/Contact";
import Product from "../components/Pages/Product";
import Header from "../components/Pages/Header";
import AllProduct from "../components/Pages/AllProduct/AllProduct";

const Routting = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header/>}>
          <Route index path="/" element={<Home/>} />
          <Route index path="/about" element={<About />} />
          <Route exact path="/our-product" element={<Product />} />
          <Route path="/all-product" element={<AllProduct/>} />
          <Route path="/service" element={<Service />} />
          <Route path="/com-licence" element={<License />} />
          <Route path="/contact" element={<Contact />} />
    </Route>
      </Routes>
     
    </BrowserRouter>
  );
};

export default Routting;
