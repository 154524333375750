import Product1 from "../assets/Product-image/Product-1.jpg";
import Product2 from "../assets/Product-image/Product-2.jpg";
import Product3 from "../assets/Product-image/Product-3.jpg";
import Product4 from "../assets/Product-image/Product-4.jpg";
import Product5 from "../assets/Product-image/Product-5.jpg";
import Product6 from "../assets/Product-image/Product-6.jpg";
import Product7 from "../assets/Product-image/Product-7.jpg";
import Product8 from "../assets/Product-image/Product-8.jpg";
import Product9 from "../assets/Product-image/Product-9.jpg";
import Product10 from "../assets/Product-image/Product-10.jpg";
import Product11 from "../assets/Product-image/Product-11.jpg";

const Sdata = [
  {
    heading: "Stainless Steel Hammer",
    imgsrc: Product1,
  },
  {
    heading: "Cast Iron Fire Hydrant Valve",
    imgsrc: Product2,
  },
  {
    heading: " LED Exit Sign Board",
    imgsrc: Product3,
  },
  {
    heading: "Red And Light Green Emergency Exit Sign Board",
    imgsrc: Product4,
  },
  {
    heading: "Fire Sprinklers",
    imgsrc: Product5,
  },
  {
    heading: "Random Access Memory (RAM)",
    imgsrc: Product6,
  },
  {
    heading: "Photoelectric Smoke Detector",
    imgsrc: Product7,
  },
  {
    heading:
      "Animation Red Emergency Exit Lights, Input Voltage: 220V, Shape: Rectangle",
    imgsrc: Product8,
  },
  {
    heading:
      "Random Access Memory (RAM) PVC Tyco 801Ap Hand Programmer Device  ",
    imgsrc: Product9,
  },
  {
    heading: "Brass Low Pressure 2 Way Fire Hydrant Valve, Valve Size: 5 inch",
    imgsrc: Product10,
  },
  {
    heading: "Plastic Morley Addressable Red Wall Mount Sounder, For Offices",
    imgsrc: Product11,
  },
];
export default Sdata;
