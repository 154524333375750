import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import logopng from "../../components/assets/logo-png.png";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [active, setActive] = useState("");
const [menuOpen, setmenuOpen]=useState(false)
  return (
    <>
      <div className=" header-container">
        <nav>
          <div className="logo-container">

            <Link to="/">
              <img src={logopng} className="logo" />
              <h1 className="logo-name">Tech Safety</h1>
            </Link>
            <div className="menu" onClick={()=>{
              setmenuOpen(!menuOpen)
             
            }}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <ul className={menuOpen? "Opne": ""}>
            <li>
              <NavLink
                id="sidebar"
                style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink  style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
               to="/about">About Us</NavLink>
            </li>

            <li>
              <NavLink  style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }} to="/our-product">Products</NavLink>
            </li>

            <li>
              <NavLink  style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }} to="/service">Service</NavLink>
            </li>
            <li>
              <NavLink  style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }} to="/com-licence">License</NavLink>
            </li>
            <li>
              <NavLink  style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }} to="/contact">Contact</NavLink>
            </li>
            <hr />
          </ul>

          <div className="btn">
            <a
              target="_blank"
              href="https://www.facebook.com/people/SafetyTech-H/61552983945991/"
            >
              <FaFacebookF color="black" size={20} />
            </a>
            <a target="_blank" href="https://www.instagram.com/safetytechhh/">
              <FaInstagram color="black" size={20} />
            </a>
            <a
            
              target="_blank"
              href="https://www.linkedin.com/in/tech-safety-4734162a8/  "
            >
              <FaLinkedin color="black" size={20} />
            </a>
          </div>
        </nav>
      </div>
      <Outlet />
    </>
  );
};

export default Header;
