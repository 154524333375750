import React from "react";

import Dproduct from "./Dproduct";

import Sdata from "./Sdata";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const ParentProuct = () => {

  function pcard(val) {
    return <Dproduct imgsrc={val.imgsrc} heading={val.heading} />;
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 884, min: 0 },
      items: 3,
      
      
    },
  };

  return (
    <>
      <div className="product-main" id="product">
        <div className="main-product-bar">
          <h1>
            OUR <span>PRODUCTS</span>
          </h1>
          <div className="line-2"></div>
          {/* <button
            className="button type1"
            onClick={() =>
              navigateallproduct("/all-product", { replace: true })
            }
          >
            <span class="btn-txt">View all products</span>
          </button> */}
        </div>

        {/* Product card */}
        <Carousel
      responsive={responsive}
      autoPlay={true}
      swipeable={true}
      draggable={true}
 
      infinite={true}
      partialVisible={false}
      dotListClass="custom-dot-list-style"
          
        >
          {Sdata.map(pcard)}
        </Carousel>
      </div>
    </>
  );
};

export default ParentProuct;
