import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Team from "./Team";

const About = () => {


    const [counterone, setcounterone] = useState(false);
  return (
    <>
    <Team/>

      <ScrollTrigger
        onEnter={() => setcounterone(true)}
        onExit={() => setcounterone(false)}
      >
        <div className="counters" id="about">
          <div className="counter">
            <h1>
              <span data-count="156">
                {counterone && (
                  <CountUp start={0} end={5} duration={2} delay={0} />
                )}
              </span>{"+ "}
             </h1>
            <p > Years of Experience</p>
          </div>
          <div className="counter">
            <h1>
              <span data-count="156">
                {counterone && (
                  <CountUp start={0} end={302} duration={2} delay={0} />
                )}
              </span>{"+ "}
             </h1>
            <p > Satisfied Customers</p>
          </div>
          <div className="counter">
            <h1>
              <span data-count="156">
                {counterone && (
                  <CountUp start={0} end={6000} duration={2} delay={0} />
                )}
              </span>{"+ "}
             </h1>
            <p >Product Delivered</p>
          </div>

          
        </div>
      </ScrollTrigger>
    </>
  );
};

export default About;
