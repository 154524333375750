import React from "react";
import Img1 from "../assets/Certificate/C-1.jpg";
import Img2 from "../assets/Certificate/C-2.jpg";
import Img3 from "../assets/Certificate/C-3.jpg";
import Img4 from "../assets/Certificate/C-4.jpg";

const License = () => {
  return (
    <>
      <div className="main-certificate" id="com-licence">
        <h2>
          Our <span>License</span>
          <div className="line-c"></div>
        </h2>
        <div className="c-image">
        <div data-aos="fade-left">
          <div className="c-1">
            <img src={Img1} alt="" />
          </div>
          </div>
          <div data-aos="fade-right">
          <div className="c-2">
            <img src={Img2} alt="" />
          </div>
          </div>
          <div data-aos="fade-down">
          <div className="c-3">
            <img src={Img3} alt="" />
          </div>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            <div className="c-4" >
              <img src={Img4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default License;
